import {
  LOGIN,
  LOGOUT,
  UPDATE_DEFAULT_CUSTOMER,
  UPDATE_DEFAULT_LANGUAGE,
  UPDATE_EMAIL_VERIFIED,
  UPDATE_USER_TOKEN,
} from "../actions/auth";

export default (prevState = { authenticated: false, user: null }, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...prevState, authenticated: true, user: action.user };
    case LOGOUT:
      return { ...prevState, authenticated: false, user: null };
    case UPDATE_DEFAULT_CUSTOMER: {
      return {
        ...prevState,
        user: {
          ...prevState.user,
          defaultCustomer_ID: action.defaultCustomerID,
          defaultCustomerName: action.defaultCustomerName,
        },
      };
    }
    case UPDATE_DEFAULT_LANGUAGE: {
      return {
        ...prevState,
        user: {
          ...prevState.user,
          defaultLanguage: action.defaultLanguage,
        },
      };
    }
    case UPDATE_EMAIL_VERIFIED: {
      return {
        ...prevState,
        user: {
          ...prevState.user,
          emailConfirmed: action.emailConfirmed,
        },
      };
    }
    case UPDATE_USER_TOKEN: {
      return {
        ...prevState,
        user: {
          ...prevState.user,
          token: action.token,
        },
      };
    }
    default:
      return prevState;
  }
};
