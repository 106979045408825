import { UPDATE_FAIR, CLEAR_FAIR } from "../actions/fair";

export default (prevState = { fair: [] }, action) => {
  switch (action.type) {
    case UPDATE_FAIR:
      return { ...prevState, fair: action.fair };
    case CLEAR_FAIR:
      return { ...prevState, fair: [] };

    default:
      return prevState;
  }
};
