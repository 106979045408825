import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { Fragment, useState, useEffect } from "react";
import { Outlet } from "react-router";
import DashboardHeader from "../layout-parts/DashboardHeader";
import DashboardSidebar from "./DashboardSidebar";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import axios from "../../utils/axios";
import { useMemo } from "react";
import { H5, Small } from "components/Typography";
import { store } from "../../store";
import { ClearAlert, UpdateAlert } from "../../store/actions/alert";
import { useLocation } from "react-router";

const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const [errorText, setErrorText] = useState("");
  const [status, setStatus] = useState(null);
  const logedIn = useSelector(
    (state) => state.persistedReducer.authReducer.authenticated
  );
  const emailVerified = useSelector(
    (state) => state.persistedReducer.authReducer?.user?.emailConfirmed
  );

  console.log(emailVerified);

  useEffect(() => {
    window.addEventListener("online", () => setStatus(navigator.onLine));
    return () =>
      window.removeEventListener("online", () => setStatus(navigator.onLine));
  });

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      handleError(err);
      console.log(err);
      const originalConfig = err.config;
      if (err.code === "ECONNREFUSED") {
        // Handle the case where the backend server is not running
        console.log("Backend server is not running");
        // You can choose to display an error message to the user or retry the request after some time
      } else if (err.response.status === 500) {
        // Handle the case where there is an internal server error
        console.log("Internal server error");
        // You can choose to display an error message to the user or retry the request after some time
      }
    }
  );

  console.log(location.pathname);

  function handleError(error) {
    store.dispatch(UpdateAlert(error));
    const timer = setTimeout(() => {
      store.dispatch(ClearAlert());
      console.log(error.toString());
    }, 10000);
  }

  const alertMessage =
    useSelector((state) => state.persistedReducer.alertReducer.alert) || "";

  const [sidebarCompact, setSidebarCompact] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const handleCompactToggle = () => setSidebarCompact(!sidebarCompact);

  const handleMobileDrawerToggle = () =>
    setShowMobileSideBar((state) => !state); // dashboard body wrapper custom style

  const customStyle = {
    width: `calc(100% - ${sidebarCompact ? "86px" : "280px"})`,
    marginLeft: sidebarCompact ? "86px" : "280px",
  };
  return (
    <Fragment>
      {logedIn ? (
        <>
          {(emailVerified != "False" && location.pathname != "verify-email") ||
          location.pathname == "/account-confirm" ? (
            <>
              <DashboardSidebar
                sidebarCompact={sidebarCompact}
                showMobileSideBar={showMobileSideBar}
                setSidebarCompact={handleCompactToggle}
                setShowMobileSideBar={handleMobileDrawerToggle}
              />
              {alertMessage != "" && (
                <Alert
                  sx={{
                    position: "fixed",
                    zIndex: 9999,
                    top: 30,
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: "15%",
                    right: 0,
                    textAlign: "center",
                    width: "50%",
                  }}
                  variant="filled"
                  severity="error"
                >
                  {(alertMessage && alertMessage?.title) ||
                  alertMessage.detail ? (
                    <>
                      <H5 sx={{ textAlign: "left" }}>
                        {" "}
                        {alertMessage && alertMessage?.title}
                      </H5>
                      <Small sx={{ textAlign: "left" }}>
                        {" "}
                        {alertMessage && alertMessage?.detail}
                      </Small>
                    </>
                  ) : (
                    <Small sx={{ textAlign: "left" }}>{alertMessage}</Small>
                  )}
                </Alert>
              )}

              <LayoutBodyWrapper sx={customStyle}>
                <DashboardHeader
                  setShowSideBar={handleCompactToggle}
                  setShowMobileSideBar={handleMobileDrawerToggle}
                />

                {/* <Alert #TODO
              sx={{
                position: "absolute",
                top: 10,
                right: "50%",
                maxWidth: "40%",
                zIndex: "100000",
              }}
              severity="error"
              variant="filled"
            >
              <AlertTitle></AlertTitle>
              {errorText}
            </Alert> */}

                {children || <Outlet />}
              </LayoutBodyWrapper>
            </>
          ) : (
            <Navigate to={"/verify-email"} />
          )}
        </>
      ) : (
        <Navigate to={"/login"} />
      )}
    </Fragment>
  );
};

export default DashboardLayout;
