import LoadingScreen from "components/LoadingScreen";
import jwtDecode from "jwt-decode";
import { createContext, useEffect, useReducer } from "react";
import axios from "../utils/axios"; // --------------------------------------------------------
import { store } from "../store";
import { Login, LoginError, Logout } from "../store/actions/auth";

// --------------------------------------------------------

export let BASE_URL = "https://portal-be.pomurski-sejem.si";
export let MEDIA_URL = " https://portal.pomurski-sejem.si/";
export let WSS = "wss://portal.pomurski-sejem.si/chat";
if (process.env.REACT_APP_STATUS == "STAGING") {
  BASE_URL = "https://stg.portal-be.pomurski-sejem.si";
  MEDIA_URL = "https://stg.portal.pomurski-sejem.si/";
} else {
  BASE_URL = "https://portal-be.pomurski-sejem.si";
  MEDIA_URL = "https://portal.pomurski-sejem.si/";
}

//axios.defaults.withCredentials = true;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

/*const isValidToken = (accessToken) => {
  if (!accessToken) return false;
  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};*/

/*const setSession = (accessToken) => {
  if (accessToken) {
    // localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    // localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};*/

/*const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }

    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    case "LOGOUT": {
      return { ...state, user: null, isAuthenticated: false };
    }

    case "REGISTER": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    default: {
      return state;
    }
  }
};*/

const AuthContext = createContext({
  //...initialState,
  method: "JWT",
  logout: () => {},
  login: (username, password) => Promise.resolve(),
  register: (
    userName,
    password,
    firstName,
    lastName,
    email,
    mobileNumber,
    defaultLanguage
  ) => Promise.resolve(),
});
export const JWTAuthProvider = ({ children }) => {
  //const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username, password) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/User/Login`,

        {
          username: username,
          password: password,
        }
      );
      console.log(data);
      //setSession(data);
      store.dispatch(Login(data));
      return data;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const responseData = error.response.data;
        console.log(responseData); // Display the response data
        // Update your UI component to show the responseData
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }
      fetch(`${BASE_URL}/User/Login`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          res.code === -1 && store.dispatch(LoginError(res.message));
        });
      console.log(error.config);
    }
  };

  const logout = () => {
    //setSession(null);
    /* dispatch({
      type: "LOGOUT",
    });*/
    store.dispatch(Logout());
  };

  useEffect(() => {
    (async () => {
      //try {
      //  const accessToken = localStorage.getItem("accessToken");
      /* if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const { data } = await axios.get("/api/auth/profile");
          dispatch({
            type: "INIT",
            payload: {
              user: data.user,
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: "INIT",
            payload: {
              user: null,
              isAuthenticated: false,
            },
          });
        }*/
      /* } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: {
            user: null,
            isAuthenticated: false,
          },
        });
      }*/
    })();
  }, []); // show loading until not initialized

  //if (!state.isInitialized) <LoadingScreen />;
  return (
    <AuthContext.Provider value={{ login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
