import { SvgIcon } from "@mui/material";

const FileCircleQuestion = (props) => {
  return (
    <SvgIcon viewBox="0 0 14 14" {...props}>
      <path d="M5.44446 3.88889V0L9.33335 3.88889H5.44446ZM7.00001 8.94444C7.00001 7.01215 8.56772 5.44444 10.5 5.44444C12.4323 5.44444 14 7.01215 14 8.94444C14 10.8767 12.4323 12.4444 10.5 12.4444C8.56772 12.4444 7.00001 10.8767 7.00001 8.94444ZM10.5 11.2778C10.8233 11.2778 11.0833 11.0177 11.0833 10.6944C11.0833 10.3712 10.8233 10.1111 10.5 10.1111C10.1767 10.1111 9.91668 10.3712 9.91668 10.6944C9.91668 11.0177 10.1767 11.2778 10.5 11.2778ZM8.94446 7.97222C8.94446 8.18611 9.11946 8.36111 9.33335 8.36111C9.54724 8.36111 9.72224 8.18611 9.72224 7.97222V7.81667C9.72224 7.68785 9.82675 7.58333 9.95557 7.58333H10.9399C11.1271 7.58333 11.2778 7.73403 11.2778 7.92118C11.2778 8.04757 11.2073 8.16181 11.0979 8.22257L10.3202 8.6309C10.1913 8.69896 10.1111 8.83021 10.1111 8.97361V9.33333C10.1111 9.54722 10.2861 9.72222 10.5 9.72222C10.7139 9.72222 10.8889 9.54722 10.8889 9.33333V9.20938L11.4601 8.91042C11.8271 8.71597 12.0556 8.31493 12.0556 7.92118C12.0556 7.30625 11.5549 6.80556 10.9399 6.80556H9.95557C9.39654 6.80556 8.94446 7.25764 8.94446 7.81667V7.97222Z" />
      <path
        className="secondary"
        d="M0 1.55556C0 0.696354 0.696354 0 1.55556 0H5.44444V3.88889H9.33333V4.82708C7.53715 5.33507 6.22222 6.98542 6.22222 8.94444C6.22222 10.3809 6.92951 11.6497 8.01354 12.4274C7.93819 12.4372 7.85799 12.4444 7.77778 12.4444H1.55556C0.696354 12.4444 0 11.7469 0 10.8889V1.55556Z"
      />
    </SvgIcon>
  );
};

export default FileCircleQuestion;
