import React from "react";
import AdminEcommerce from "./AdminEcommerce";
import Dashboard from "./Dashboard";
import Ecommerce from "./Ecommerce";
import ElementHub from "./ElementHub";
import Logout from "./Logout";
import ProjectChart from "./ProjectChart";
import Settings from "./Settings";
import UserProfile from "./UserProfile";
import TableList from "./DataTable";
import Accounts from "./Accounts";
import Calender from "./Calender";
import Chat from "./Chat";
import Invoice from "./Invoice";
import TodoList from "./TodoList";
import Session from "./Session";
import Pages from "./Pages";
import Pricing from "./Pricing";
import DataTable from "./DataTable";
import ArrowLeftToLine from "./ArrowLeftToLine";
import MessagesDollar from "./MessagesDollar";
import BadgeDollar from "./BadgeDollar";
import PersonChalkboard from "./PersonChalkboard";
import CommentsQuestionCheck from "./CommentsQuestionCheck";
import PersonCircleCheck from "./PersonCircleCheck";
import RectangleCirclePlus from "./RectangleCirclePlus";
import DiagramProject from "./DiagramProject";
import LayerGroup from "./LayerGroup";
import FileCircleQuestion from "./FileCircleQuestion";
import UserList from "./UserList"; // eslint-disable-next-line import/no-anonymous-default-export

function Switch({ name }) {
  switch (name) {
    case "AdminEcommerce":
      return <AdminEcommerce />;
    case "ArrowLeftToLine":
      return <ArrowLeftToLine />;
    case "Dashboard":
      return <Dashboard />;
    case "DiagramProject":
      return <DiagramProject />;
    case "Ecommerce":
      return <Ecommerce />;
    case "ElementHub":
      return <ElementHub />;
    case "FileCircleQuestion":
      return <FileCircleQuestion />;
    case "Logout":
      return <Logout />;
    case "ProjectChart":
      return <ProjectChart />;
    case "Settings":
      return <Settings />;
    case "UserProfile":
      return <UserProfile />;
    case "UserList":
      return <UserList />;
    case "TableList":
      return <TableList />;
    case "Calender":
      return <Calender />;
    case "Chat":
      return <Chat />;
    case "Invoice":
      return <Invoice />;
    case "TodoList":
      return <TodoList />;
    case "Session":
      return <Session />;
    case "Pages":
      return <Pages />;
    case "Pricing":
      return <Pricing />;
    case "DataTable":
      return <DataTable />;
    case "LayerGroup":
      return <LayerGroup />;
    case "MessagesDollar":
      return <MessagesDollar />;
    case "BadgeDollar":
      return <BadgeDollar />;
    case "RectangleCirclePlus":
      return <RectangleCirclePlus />;
    case "PersonChalkboard":
      return <PersonChalkboard />;
    case "PersonCircleCheck":
      return <PersonCircleCheck />;
    default:
      return null;
  }
}

export default function SelectIconByName(props) {
  return <Switch name={props.name} />;
}
