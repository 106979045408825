import { SvgIcon } from "@mui/material";

const Accounts = (props) => {
  return (
    <SvgIcon viewBox="0 0 18 18" {...props}>
      <path d="M17.1706 10.4993C17.2445 10.1055 17.2445 9.69769 17.1706 9.30393L17.8949 8.88205C17.9792 8.83634 18.0179 8.7379 17.9898 8.64298C17.7999 8.03828 17.4765 7.48617 17.0546 7.02913C16.9913 6.95882 16.8859 6.94124 16.8015 6.99046L16.0772 7.40897C15.7712 7.14881 15.4197 6.9449 15.04 6.8113V5.97105C15.04 5.87613 14.9697 5.79175 14.8783 5.77417C14.2525 5.63354 13.6126 5.63706 13.0149 5.77417C12.9235 5.79175 12.8567 5.87613 12.8567 5.97105V6.81144C12.477 6.94504 12.1255 7.14895 11.8195 7.40911L11.0927 6.9892C11.0119 6.93998 10.9064 6.95755 10.8396 7.02787C10.4177 7.48491 10.0943 8.03701 9.90779 8.64171C9.87966 8.73664 9.91834 8.83508 9.9992 8.88078L10.7234 9.30266C10.6531 9.69642 10.6531 10.1042 10.7234 10.498L9.9992 10.9199C9.91482 10.9656 9.87966 11.064 9.90779 11.159C10.0941 11.7637 10.4177 12.3121 10.8396 12.7728C10.9064 12.8431 11.0083 12.8607 11.0927 12.8115L11.8206 12.393C12.1266 12.6531 12.4782 12.857 12.8579 12.9906V13.831C12.8579 13.9259 12.9247 14.0103 13.0161 14.0279C13.6455 14.1685 14.2817 14.165 14.8794 14.0279C14.9708 14.0103 15.0411 13.9259 15.0411 13.831V12.9906C15.4208 12.857 15.7724 12.6531 16.0784 12.393L16.8026 12.8115C16.8835 12.8607 16.9924 12.8431 17.0557 12.7728C17.4776 12.3158 17.8011 11.7637 17.9909 11.159C18.019 11.064 17.9804 10.9656 17.896 10.9199L17.1706 10.4993ZM13.9503 11.2643C13.1979 11.2643 12.5862 10.6526 12.5862 9.90019C12.5862 9.14783 13.1979 8.5361 13.9503 8.5361C14.7026 8.5361 15.3143 9.14783 15.3143 9.90019C15.3143 10.6526 14.704 11.2643 13.9503 11.2643Z" />
      <path
        d="M6.30012 7.20014C8.2886 7.20014 9.90019 5.58826 9.90019 3.60007C9.90019 1.61187 8.2886 0 6.30012 0C4.31164 0 2.70005 1.61187 2.70005 3.60007C2.70005 5.58826 4.31164 7.20014 6.30012 7.20014ZM11.9562 13.8321V13.5742C11.8914 13.539 11.8276 13.5022 11.7647 13.4636L11.5448 13.59C11.376 13.6886 11.1839 13.7405 10.9893 13.7405C10.6881 13.7405 10.395 13.6142 10.1745 13.3819C9.6567 12.8169 9.26632 12.1402 9.04329 11.416C8.89276 10.908 9.10919 10.379 9.54477 10.1436L9.77028 10.0122C9.76841 9.93852 9.76841 9.86481 9.77028 9.7912L9.55498 9.66573C9.20903 9.47018 9.00934 9.11279 9.00259 8.7269C8.59516 8.61485 8.16765 8.55016 7.72608 8.55016H4.87416C2.18282 8.55016 0 10.7355 0 13.4271C0 13.9643 0.436508 14.4003 0.974831 14.4003H11.6243C11.7854 14.4003 11.9309 14.3517 12.0647 14.2825C12.0012 14.1415 11.9562 13.9925 11.9562 13.8321Z"
        className="secondary"
      />
    </SvgIcon>
  );
};

export default Accounts;
