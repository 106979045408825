import axios from "axios";
import { store } from "../store";
import { BASE_URL } from "../contexts/JWTAuth";
import { ClearAlert, UpdateAlert } from "../store/actions/alert";
import { updateToken } from "../store/actions/auth";
import { Logout } from "../store/actions/auth";

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error?.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        
        const token =
          store.getState()?.persistedReducer?.authReducer?.user?.token;
           
        const data = await axios.post(
          `${BASE_URL}/user/RefreshToken`,
          { token },
          {}
        );
        store.dispatch(updateToken(data?.data));
        return axiosInstance(originalRequest);
      } catch (e) {
        // If the RefreshToken endpoint returns an error, call the logout function

        if (e.response && e.response.status !== 200) {
          store.dispatch(Logout());
        }
        return Promise.reject(e);
      }
    } else if (error.code === "ECONNREFUSED") {
      handleError("An error occurred while processing your request.");
    } else if (error.response.status === 500) {
      handleError("An error occurred while processing your request.");
    }

    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

function handleError(error) {
  store.dispatch(UpdateAlert(error));
  const timer = setTimeout(() => {
    store.dispatch(ClearAlert());
    console.log(error.toString());
  }, 10000);
}

export default axiosInstance;
