export const UPDATE_FAIR = "UPDATE_FAIR";
export const CLEAR_FAIR = "CLEAR_FAIR";

export const UpdateFair = (fair) => {
  return {
    type: UPDATE_FAIR,
    fair: fair,
  };
};

export const ClearFair = () => {
  return {
    type: CLEAR_FAIR,
  };
};
