import { CheckBox, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import IconWrapper from "components/IconWrapper";
import { H5, Small } from "components/Typography";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { lightTheme } from "constants";
import FileIcon from "icons/FileIcon";
import PencilIcon from "icons/PencilIcon";
import { MEDIA_URL } from "contexts/JWTAuth";
import { useState } from "react";

const tableHeadColor = (theme) =>
  lightTheme(theme) ? "primary.100" : "divider";

export default function FileItem(props) {
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileToDelete, setfileToDelete] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isLight = theme.palette.mode === "light";
  const handleOpenModalDelete = () => {
    setDeleteModal(true);
  };
  const handleCloseModalDelete = () => {
    console.log("close modal");
    setDeleteModal(false);
  };
  return (
    <Box px={2}>
      <TableContainer component={Paper} sx={{ boxShadow: "unset !important" }}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow
              // sx={{ backgroundColor: (theme) => tableHeadColor(theme) }}
              sx={{
                display: { xs: "inline-table", sm: "table-row" },
                minWidth: "100%",
                backgroundColor: isLight ? "#F5F5F7" : "#171c24",
              }}
            >
              <TableCell
                padding="normal"
                sx={{
                  paddingLeft: "16px !important",
                  py: "6px !important",
                  borderBottom: "1px solid #BABFC5 !important",
                  width: "40%",
                }}
              >
                {t("File type")}
              </TableCell>
              {/* <TableCell>{t("File")}</TableCell> */}
              <TableCell
                align="left"
                sx={{
                  py: "6px !important",
                  borderBottom: "1px solid #BABFC5 !important",
                  width: "40%",
                }}
              >
                {t("Description")}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  py: "6px !important",
                  paddingRight: "16px !important",
                  borderBottom: "1px solid #BABFC5 !important",
                  width: "20%",
                }}
              >
                <div style={{ paddingRight: 16 }}>{t("Edit")}</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.listItems?.map((file, index, _) => {
              return (
                <TableRow
                  key={file?.filePath}
                  onClick={() => {
                    window.open(`${MEDIA_URL}${file?.filePath}`);
                  }}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },

                    display: { xs: "block", sm: "table-row" },
                  }}
                  hover
                >
                  <TableCell
                    padding="normal"
                    sx={{
                      paddingLeft: "16px !important",
                      borderBottom:
                        _.length === index + 1
                          ? "none"
                          : "1px solid #BABFC5 !important",
                      width: "40%",
                    }}
                    align="left"
                  >
                    <FlexBox sx={{ flexDirection: "row" }}>
                      {file.FileTypeImageUrl && (
                        <IconWrapper
                          style={{
                            backgroundColor: "transparent",
                            width: "auto",
                          }}
                        >
                          {/* <FileIcon
                          sx={{
                            color: "primary.main",
                          }}
                        /> */}

                          <CardMedia
                            image={file.FileTypeImageUrl}
                            title={file?.fileType}
                            sx={{ width: 25, height: 25 }}
                          />
                        </IconWrapper>
                      )}
                      <FlexBox
                        sx={{
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <H5>{file?.fileType}</H5>
                        <Small>{file.time}</Small>
                      </FlexBox>
                    </FlexBox>
                  </TableCell>
                  {/* <TableCell>
                    {file?.filePath?.replace(/storage\\([\w\-\.]+)$/, "$1")}
                  </TableCell> */}
                  <TableCell
                    align="left"
                    sx={{
                      borderBottom:
                        _.length === index + 1
                          ? "none"
                          : "1px solid #BABFC5 !important",
                      width: "40%",
                    }}
                  >
                    {file.fileDescription}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      borderBottom:
                        _.length === index + 1
                          ? "none"
                          : "1px solid #BABFC5 !important",
                      lineHeight: "unset",
                      width: "20%",
                    }}
                  >
                    <div>
                      {/* {!!file.checked && ( */}
                      <IconButton
                        disabled={false}
                        onClick={(event) => {
                          event.stopPropagation();
                          props.onEditFiles(file);
                        }}
                      >
                        <PencilIcon
                          sx={{
                            color: "text.disabled",
                            fontSize: 20,
                            textAlign: "right",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        disabled={false}
                        onClick={(event) => {
                          event.stopPropagation();
                          // props.onDeleteFile(file);
                          setfileToDelete(file);
                          handleOpenModalDelete();
                        }}
                      >
                        {
                          <Dialog
                            open={deleteModal}
                            //TransitionComponent={Transition}
                            keepMounted
                            onClose={handleCloseModalDelete}
                            aria-describedby="alert-dialog-slide-description"
                            onClick={(event) =>  event.stopPropagation()}
                          >
                            <DialogTitle>
                              {t("Do you really want to delete this file?")}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-slide-description">
                                {fileToDelete?.fileType} (
                                {fileToDelete?.fileDescription})
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                variant="outlined"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setDeleteModal(false);
                                }}
                              >
                                {t("Cancel")}
                              </Button>
                              <Button
                                variant="contained"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleCloseModalDelete();
                                  props.onDeleteFile(fileToDelete);
                                  setfileToDelete(null);
                                }}
                              >
                                {t("Delete")}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        }

                        <Delete
                          sx={{
                            color: "text.disabled",
                            fontSize: 24,
                            textAlign: "right",
                          }}
                        />
                      </IconButton>

                      {/* )} */}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        {props.listItems?.length === 0 && (
          <>
            <H5 mt={4} mb={2} sx={{ textAlign: "center" }}>
              {t("No files!")}
            </H5>
          </>
        )}
        {!props.listItems && (
          <FlexBox my={8} justifyContent="center" alignItems="center">
            <CircularProgress />
          </FlexBox>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex !important",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Divider
            sx={{
              mt: 1,
              mb: 2,
              width: "98%",
            }}
          ></Divider> */}
        </Box>
      </TableContainer>
    </Box>
  );
}
