import { SvgIcon } from "@mui/material";

const Invoice = (props) => {
  return (
    <SvgIcon viewBox="0 0 14 18" {...props}>
      <path d="M2.91669 5.83333C2.91669 5.5125 3.17773 5.25 3.50002 5.25H10.5C10.8209 5.25 11.0834 5.5125 11.0834 5.83333C11.0834 6.15417 10.8209 6.41667 10.5 6.41667H3.50002C3.17773 6.41667 2.91669 6.15417 2.91669 5.83333ZM2.91669 12.8333C2.91669 12.5125 3.17773 12.25 3.50002 12.25H10.5C10.8209 12.25 11.0834 12.5125 11.0834 12.8333C11.0834 13.1542 10.8209 13.4167 10.5 13.4167H3.50002C3.17773 13.4167 2.91669 13.1542 2.91669 12.8333ZM10.5 8.75C10.8209 8.75 11.0834 9.0125 11.0834 9.33333C11.0834 9.65417 10.8209 9.91667 10.5 9.91667H3.50002C3.17773 9.91667 2.91669 9.65417 2.91669 9.33333C2.91669 9.0125 3.17773 8.75 3.50002 8.75H10.5Z" />
      <path
        className="secondary"
        d="M0.509323 0.0800625C0.819948 -0.0627083 1.1849 -0.0117177 1.44448 0.210656L2.91667 1.47255L4.38958 0.210656C4.71771 -0.0702188 5.19896 -0.0702188 5.52708 0.210656L7 1.47255L8.47292 0.210656C8.80104 -0.0702188 9.28229 -0.0702188 9.61042 0.210656L11.0833 1.47255L12.5562 0.210656C12.8151 -0.0117177 13.1797 -0.0627083 13.4896 0.0800625C13.7995 0.22287 14 0.533385 14 0.875V17.7917C14 18.1344 13.7995 18.4443 13.4896 18.5865C13.1797 18.7286 12.8151 18.6776 12.5562 18.4552L11.0833 17.1937L9.61042 18.4552C9.28229 18.7359 8.80104 18.7359 8.47292 18.4552L7 17.1937L5.52708 18.4552C5.19896 18.7359 4.71771 18.7359 4.38958 18.4552L2.91667 17.1937L1.44448 18.4552C1.1849 18.6776 0.819948 18.7286 0.509323 18.5865C0.198917 18.4443 0 18.1344 0 17.7917V0.875C0 0.533385 0.198917 0.222833 0.509323 0.0800625ZM3.5 5.25C3.17771 5.25 2.91667 5.5125 2.91667 5.83333C2.91667 6.15417 3.17771 6.41667 3.5 6.41667H10.5C10.8208 6.41667 11.0833 6.15417 11.0833 5.83333C11.0833 5.5125 10.8208 5.25 10.5 5.25H3.5ZM3.5 13.4167H10.5C10.8208 13.4167 11.0833 13.1542 11.0833 12.8333C11.0833 12.5125 10.8208 12.25 10.5 12.25H3.5C3.17771 12.25 2.91667 12.5125 2.91667 12.8333C2.91667 13.1542 3.17771 13.4167 3.5 13.4167ZM3.5 8.75C3.17771 8.75 2.91667 9.0125 2.91667 9.33333C2.91667 9.65417 3.17771 9.91667 3.5 9.91667H10.5C10.8208 9.91667 11.0833 9.65417 11.0833 9.33333C11.0833 9.0125 10.8208 8.75 10.5 8.75H3.5Z"
      />
    </SvgIcon>
  );
};

export default Invoice;
