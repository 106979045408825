import { Button, IconButton, styled } from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "components/flexbox/FlexBox";
import SearchInput from "components/input-fields/SearchInput";
import { lightTheme } from "constants";
import { t } from "i18next";
import Add from "icons/Add";
import Delete from "icons/Delete";

const StyledSearchInput = styled(SearchInput)(({ theme }) => ({
  backgroundColor: lightTheme(theme)
    ? theme.palette.action.hover
    : theme.palette.divider,
}));

export default function FilesSearch(props) {
  return (
    <Box my={1} px={2}>
      <FlexBox
        justifyContent="flex-end"
        flexDirection="row"
        alignItems="center"
      >
        <Button
          variant="string"
          sx={{ whiteSpace: "nowrap", color: "#2499EF", fontSize: 12 }}
          endIcon={<Add sx={{ width: 36, height: "auto" }} />}
          onClick={async () => {
            console.log("clicked");
            props.addNewFile();
          }}
        >
          {t("New file")}
        </Button>
      </FlexBox>
      <FlexBox
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
      >
        <SearchInput
          sx={{
            borderWidth: 0,
          }}
          placeholder={t("Search files")}
          onChange={props.onSerachType}
        />
      </FlexBox>
    </Box>
  );
}
